import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Grid, Typography, Alert, Box, IconButton, Divider } from '@mui/material';
import { LinkedIn, Twitter, Instagram, Facebook, YouTube } from '@mui/icons-material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import upperIcon from './upperIcon.png';
import background_img from './background_img.png';
import upperLeft from './upperLeft.png';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import YouTubeIcon from '@mui/icons-material/YouTube';

function App() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [formData, setFormData] = useState({ name: '', email: '', phoneNumber: '' });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [notification, setNotification] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      const response = await axios.post('https://bssproxy01.neotel.nr/mis-reports/api/customers/register', formData);
      setSuccess(response.data);
      setNotification("Registration successful!");
      setFormData({ name: '', email: '', phoneNumber: '' });
      setTimeout(() => setNotification(null), 2000);
    } catch (error) {
      if (error.response && error.response.data) {
        setError(error.response.data);
        setNotification("An error occurred. Please try again.");
        setTimeout(() => setNotification(null), 4000);
      } else {
        setError("An error occurred. Please try again.");
        setNotification("An error occurred. Please try again.");
        setTimeout(() => setNotification(null), 4000);
      }
    }
  };

  return (
    <Box className="app-container">
      <header>
        <Grid container sx={{ padding: '0px', justifyContent: 'center' }}>
          {/* Upper Icons and Form */}
          <Grid
            item
            xs={12}
            md={1.5}
            sx={{
              display: { xs: 'none', lg: 'block' }, // Show only on large screens
              textAlign: 'left' // Text alignment for large screens
            }}
          >
            <img
              src={upperLeft}
              alt="Upper Icon"
              style={{ width: '60%', maxHeight: '200px', objectFit: 'contain' }}
            />
          </Grid>


          <Grid item lg={4} xs={12} paddingTop={6} paddingRight={2}>
            <Grid container justifyContent="center">
              <Grid item xs={12} sx={{textAlign:{ xs: 'center', sm: 'center', md: 'center',  lg: 'left',xl:'left'}}}>
                <img
                  src={upperIcon}
                  alt="Upper Icon"
                  style={{ width: '60%', maxHeight: '200px', objectFit: 'contain' }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{
                  fontSize: { xs: '17px', sm: '23px' },
                  fontWeight: '800', color: '#495BA7', textAlign: { xs: 'center', sm: 'center', md: 'center', lg: 'left',xl:'left'}
                }}>
                  WELCOME TO THE
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{
                  fontSize: { xs: '17px', sm: '23px' },
                  fontWeight: '800', color: '#FCB714', textAlign: { xs: 'center', sm: 'center', md: 'center',  lg: 'left',xl:'left'}
                }}>
                  FUTURE OF CONNECTIVITY,
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{
                  fontSize: { xs: '17px', sm: '23px' },
                  fontWeight: '800', color: '#495BA7',
                  textAlign: { xs: 'center', sm: 'center', md: 'center', lg: 'left',xl:'left'}
                }}>
                  NAURU!
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{
                  fontSize: { xs: '17px', sm: '15px' },
                  color: '#495BA7', textAlign: { xs: 'center', sm: 'center', md: 'center', lg: 'left',xl:'left'}
                }}>
                  Get ready to explore the possibilities with our groundbreaking 5G Plus technology.
                </Typography>
              </Grid>
              <Grid item xs={12} paddingTop={1}>
                <Typography sx={{
                  fontSize: { xs: '17px', sm: '15px' },
                  color: '#495BA7', textAlign: { xs: 'center', sm: 'center', md: 'center', lg: 'left',xl:'left'}
                }}>
                  Share Your Details for exciting Launch offers
                </Typography>
              </Grid>
              <Grid item xs={12} textAlign="center" sx={{ padding: { xs: 2, md: 2,lg:0 } }}>
                <form onSubmit={handleSubmit}>
                  <TextField
                    fullWidth
                    label="Name"
                    variant="outlined"
                    size="small"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="Email"
                    variant="outlined"
                    size="small"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    margin="normal"
                  />
                  <TextField
                    fullWidth
                    label="Phone Number"
                    variant="outlined"
                    size="small"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    margin="normal"
                  />
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ bgcolor: '#495AA8', fontSize: '0.800rem', marginTop: '10px' }}
                  >
                    Submit
                  </Button>
                </form>
              </Grid>

              {/* Social Media Icons */}
              <Grid item xs={12} paddingTop={2} display="flex" justifyContent="center">
                <IconButton href="https://www.linkedin.com" target="_blank">
                  <LinkedInIcon sx={{ color: '#316FF6' }} />
                </IconButton>
                <IconButton href="https://www.facebook.com" target="_blank">
                  <FacebookIcon sx={{ color: '#316FF6' }} />
                </IconButton>
                <IconButton href="https://www.instagram.com" target="_blank">
                  <InstagramIcon sx={{ color: '#E1306C' }} />
                </IconButton>
                <IconButton href="https://www.twitter.com" target="_blank">
                  <XIcon sx={{ color: '#1DA1F2' }} />
                </IconButton>
                <IconButton href="https://www.youtube.com" target="_blank">
                  <YouTubeIcon sx={{ color: '#FF0000' }} />
                </IconButton>
              </Grid>
              <Grid item xs={12}>
                <Divider
                  sx={{
                    borderColor: '#FCB714',
                    borderWidth: '1.5px',
                    width: '92.5%',
                    margin: '3px auto',
                  }}
                />
              </Grid>
              <Grid item xs={12} textAlign="center">
                <Typography sx={{ fontSize: { xs: '17px', sm: '17px' }, fontWeight: '550', color: '#495BA7', paddingBottom: 2 }}>
                  Coming Soon
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Background Image */}
          <Grid item lg={6.5} xs={12}
            sx={{ position: 'relative', marginLeft: 0 }}>

            <Box
              component="img"
              src={background_img}
              alt="Background Icon"
              // sx={{
              //   position: 'absolute',
              //   bottom: {lg:45,},
              //   right: 0,
              //   width: '100%',
              //   height: 'auto',
              //   maxHeight: '400px',
              //   objectFit: 'fill'
              // }}
              sx={{
                width: '100%',
                height: 'auto',

                maxHeight: { xs: '300px', md: '400px' },
                objectFit: 'fill',
                paddingTop: { lg: 26.5 },
                bottom: 0,
                right: 0
              }}
            />
          </Grid>

        </Grid>

        {notification && (
          <Alert severity="info" sx={{
            position: 'absolute',
            bottom: '20px',
            left: '20px',
            width: 'auto',
            zIndex: 1000,
          }}>
            {notification}
          </Alert>
        )}
      </header>

      {/* Footer */}
      <footer
        className="footer"
        style={{
          backgroundColor: '#495AA8',
          width: '100%',
          height: '40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography variant="body2" sx={{ textAlign: 'center', color: 'white' }}>
          &copy; 2024 Telecom Nauru Corporation. All rights reserved.
        </Typography>
      </footer>

    </Box>
  );
}

export default App;
